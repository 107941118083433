@import '../Helper.scss';

div.profile-card {
    @media (min-width: 300px) {
        width: 35vw;
        height: 35vw;

        @include flexbox();
        @include flex-flow(column nowrap);
        @include justify-content(space-evenly);
        @include align-items(center);
        
        img.icon {
            height: 40px;
            width: 40px;
        }

        background-color: $color-white;
        border-radius: 20px;
        
        @include box-shadow(0px 10px 15px 0px rgba(0, 0, 0, .25));
        
        text-align: center;

        
        
        h2 + p + p {
            line-height: 0;
            padding: 0 0 0 0;
            margin: 0 0 0 0;
        }

        p {
            font-size: .8em;
        }
    }

    @media (min-width: 500px) {
        width: 20vw;
        height: 20vw;
    }

    @media (min-width: 900px) {
        width: 15vw;
        height: 15vw;

        padding: 0 0 0 0;
        margin: 0 0 0 0;
    }

    @media (min-width: 1600px) {
        width: 5.5vw;
        height: 5.5vw;

        &.card-active {
            animation: hoverElement .5s normal forwards;
        }
        
        &.card-inactive {
            animation: unhoverElement .5s normal forwards;
        }

        img.icon {
            height: 30px;
            width: 30px;
            
            padding: 0 0 0 0;
            margin: 0 0 0 0;
            margin-top: 10px; 
        
            &:hover {
                animation: hoverElement .5s normal forwards;
                filter: drop-shadow(2px 2px 0 $color-hot-pink)
            };
        }
    }
}
