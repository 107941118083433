@import '../Helper.scss';

$blob-count: 50;
$steps: random(100) + 10;
$colors: $color-hot-pink, $color-medium-purple, $color-light-purple, $color-light-pink;

@keyframes scaleElement {
  to {
      transform: scale(8);
    }
}

div.AnimatedBackground {
    // MacBook (13") = 1280px
    @media (min-width: 1000px) {
        position: absolute;
        z-index: -1;
        
        background-color: lighten($color-light-pink, 10%);
        
        top: 0;
        left: 0;
        
        height: 100vh;
        width: 100vw;
        
        div.background-blur {
            z-index: 0;
    
            height: 100vh;
            width: 100vw;
            background-color: rgba(255, 255, 255, .9);
            background:
                radial-gradient(120.35% 120.35% at 50% -10.05%,
                rgba(255, 255, 255, 0.30) 0%,
                rgba(255, 255, 255, 0) 100%);
            backdrop-filter: blur(100px);
        }
        div.blobs {
            position: absolute;
            border-radius: 100%;
            z-index: -1;
            
            opacity: .15;

            animation: scaleElement 1000s linear infinite;
            animation: 1000s ease both infinite alternate;
            @for $i from 1 through $blob-count {

                &:nth-of-type(#{$i}) {
                    $size: random(1000);

                    top: random(200) - 100 + vh;
                    left: random(200) - 100 + vw;
        
                    height: $size + px;
                    width: $size + px;
                
                    background-color: nth($colors, random(length($colors)));

                    animation-name: move-path-#{$i};
                }

                @keyframes move-path-#{$i} {
                    $random: random(100);

                    @for $step from 0 through $steps {
                        #{$step / $steps * 100%} {
                            transform: translateX($random - 50vw) translateX($step * $step * 150px)
                            translateY($random - 50vh) translateY($step * $step * 150px)
                            scale(random(70) / 100 + 0.3);
                        }
                    }
                }
            }
        }
    }
}