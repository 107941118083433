@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500&display=swap');

// COLORS
$color-white: #fff;
$color-black: #000;
$color-dark-grey: #363636;
$color-medium-grey: #c4c4c4;
$color-light-grey: #f6f6f6;

$color-dark-purple: #292351;
$color-medium-purple: #46389C;
$color-light-purple: #a496fc;
$color-light-pink: #cb7bbe;
$color-hot-pink: #dc33c1;

// FONTS
$font-size: 1em;
$font-line-height: 1.4em;
$font-weight: 300;
$font-stack: 'Roboto Mono', monospace;
$font-heading: 'Merriweather';

.underline {
  border-bottom: 1px solid currentColor;
  display: inline-block;
  line-height: 1.15em;
  text-shadow:
    2px 2px white,
    2px -2px white,
    -2px 2px white,
    -2px -2px white;
}

// MIXINS
@mixin gradient($direction, $list) { 
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);  
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

// Flexbox mixins via https://gist.github.com/richardtorres314/26b18e12958ba418bb37993fdcbfc1bd
// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
// Flex Flow Direction
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
     -moz-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}
// Flex Direction and Wrap
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
     -moz-flex-flow: $flow;
      -ms-flex-flow: $flow;
          flex-flow: $flow;
}
// Axis Alignment
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}
// Cross-axis Alignment
@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
       -ms-flex-align: $align;
          align-items: $align;
}

@keyframes hoverElement {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

@keyframes unhoverElement {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}


// RESETS
a {
  color: inherit;
  text-decoration: none;
}
ul {
  list-style-type: none;
}