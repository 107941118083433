@import '../../Helper.scss';

div.crafter {
    @media (min-width: 300px) {
        width: 80vw;
        margin-left: 5vw;
        text-align: center;
        
        border-top: 1px solid $color-hot-pink;
    }

    @media (min-width: 1000px) {
        width: 65vw;
        margin-left: 2.5vw;
    
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
    
        border: none;
        
        div.user-cell {
            width: 20vw;
            text-align: center;
    
        }
    }
}