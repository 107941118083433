@import '../../Helper.scss';

div.modal-bg {
    @media (min-width: 300px) {
        position: fixed;
        height: 100vh;
        width: 100vw;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        div.modal {
            z-index: 999;
            position: fixed;

            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background-color: $color-white;

            div.modal-heading {
                margin-top: 3vh;

                @include flexbox();
                @include flex-flow(row nowrap);
                @include justify-content(space-evenly);
                @include align-items(center);

                h1 {
                    font-size: 1.5em;
                    font-weight: 300;
                }

                button {
                    height: 1.25em;
                    width: 1.25em;

                    font-size: 2em;

                    background-color: none;
                    border: none;
                }
            }
        }
    }

    @media (min-width: 1000px) {
        background-color: rgba(54,54,54,.5);

        div.modal {
            margin-top: 10vh;
            margin-left: 10vw;
            
            height: 80vh;
            width: 80vw;

            border-radius: 20px;

            div.modal-heading {
                margin-top: 5vh;

                @include justify-content(center);
                @include align-items(center);

                h1 {
                    font-size: 2em;
                    font-weight: 300;
                }

                button {
                    height: 2em;
                    width: 2em;

                    position: fixed;
                    margin-left: 69vw;

                    font-size: 2em;

                    background-color: none;
                    border: none;

                    &:hover {
                        cursor: grab;

                        color: $color-hot-pink;
                        @include box-shadow(0px 5px 5px 0px rgba(0, 0, 0, .25));
                    }
                }
            }
        }
    }
}
