@import '../../Helper.scss';

div.form {
    @media (min-width: 300px) {
        width: 90vw;
        margin-left: 5vw;

        .discord {
            .success {
                color: green;
                font-size: .7em;

                margin-bottom: -1vh;
            }
            
            div.discord-profile {
                @include flexbox();
                @include flex-flow(row nowrap);
                @include justify-content(start);
                @include align-items(center);

                .discord-icon {
                    height: 50px;
                    width: 50px;

                    border-radius: 50%;
                    margin-right: 1.5vw;
                }
                
                p {
                    margin-left: 2vw;
                    font-size: 1.5em;
                }
            }
        }

        label {
            font-size: 1.25em;
        }

        input[type=text] {
            width: 80vw;
            margin: 1vh 0 1.5vh 0;

            height: 1.5em;
            padding: 10px;

            font-size: 1.25em;
            font-family: $font-stack;

            border-radius: 5px;
            border: 1px solid darken($color-light-grey, 25%);

            &::placeholder {
                color: #8b8b8b;
            }
        }

        .checkbox {
            font-size: 1em;
            line-height: 1.75em;
            margin-bottom: 1vh;
        }

        button.submit {
            height: 3em;
            width: 85vw;

            margin-top: 2.5vh;

            font-family: $font-stack;
            color: white;
            font-weight: 400;

            background-color: $color-hot-pink;
            border-radius: 5px;

            &:hover {
                @include box-shadow(0px 5px 5px 0px rgba(0, 0, 0, .25));
                cursor: grab;
            }
        }
    }

    @media (min-width: 1000px) {
        margin-top: -1vh;

        width: 80vw;
        height: 50vh;
        margin-left: 0;

        @include flexbox();
        @include flex-flow(column nowrap);
        @include justify-content(space-between);
        @include align-items(center);
        
        div {
            width: 20vw;
            padding-top: 15px;

            @include flexbox();
            @include flex-flow(column nowrap);
            @include justify-content(center);
            @include align-items(space-around);

            font-size: 1.5em;

            label {
                font-size: 1em;
            }

            input[type=text] {
                width: auto;

                margin-top: 1vh;
                margin-bottom: 1.5vh;
                
                height: 1.5em;
                padding: 10px;

                font-size: 1.25em;
                font-family: $font-stack;

                border-radius: 5px;
                border: 1px solid darken($color-light-grey, 25%);

                &::placeholder {
                    color: #8b8b8b;
                }
            }

            .checkbox {
                font-size: .7em;
                margin-bottom: 1vh;
                line-height: auto;
            }

            button.submit {
                height: 3em;
                width: auto;
                
                font-family: $font-stack;
                color: white;
                font-weight: 400;
                
                border: none;
                border-radius: 5px;
                background-color: $color-hot-pink;
                @include box-shadow(0px 5px 5px 0px rgba(0, 0, 0, .25));

                &:hover {
                    cursor: grab;
                }
            }
        }
        
        .discord {
        
            div.discord-profile {
                flex-flow: row nowrap !important;
                align-items: center;
                justify-content:  start !important;        
        
                .discord-icon {
                    height: 75px;
                    width: 75px;
                
                    margin-right: 1.5vw;
                }
        
                p {
                    margin-left: 0;

                    font-size: .8em;
                }
            }
        
        }
    }
}

