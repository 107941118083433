@import '../Helper.scss';

html {
    overflow: hidden;

    height: 100vh;
    width: 100vw;
    
    background-color: rgb(227, 187, 228);
    
    font-family: $font-stack;
    
    body {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        
        div.TorisWebsite {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(space-evenly);
            @include align-items(center);

            
            h1 {
                margin-top: 5vh;

                @media (min-width: 1000px) {
                    margin-top: 10vh;
                }

                filter: drop-shadow(1px 1px 0 $color-hot-pink);

                font-weight: 100;
            }
        }
    }
}