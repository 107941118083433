@import '../../Helper.scss';

@keyframes fadeOut {
    0% { opacity: 1;}
    30% { opacity: 1;}
    99% { opacity: 0.01;}
    100% { opacity: 0;}
}


div.heading {
    @media (min-width: 300px) {
        width: 90vw;
        height: 15vh;

        margin-top: 4vh;

        @include flexbox;
        @include flex-direction(column);
        @include justify-content(space-evenly);

        div.ip-section {
            width: 90vw;
            height: 3em;

            @include flexbox;
            @include flex-direction(row);
            @include justify-content(center);
            @include align-items(center);
            
            background-color: $color-white;
            border-radius: 10px;
            @include box-shadow(0px 10px 15px 0px rgba(0, 0, 0, .25));

            h1 {
                line-height: 10px;
                font-size: 1.2em;
                font-weight: 300;
            }
    
            p {
                line-height: 1;
                margin-right: 10px;
                font-size: .8em;
                font-weight: 300;
            }
        }

        div.ip-selection-plus-copied {
            @include flexbox;
            @include flex-direction(column);
            @include align-items(center);
            
            p.copied {
                opacity: 0;

                color: $color-dark-purple;
                font-weight: 500 !important;
                font-size: .5em;
                margin-bottom: 2vh;

                &.is-visible {
                    opacity: 1;
    
    
                    animation: fadeOut 1.5s;
                }
            }
        }
    

        button.modal-button {
            margin-top: -2vh;
            width: 90vw;

            height: 3em;
            padding: 10px;
            
            font-family: $font-stack;
            font-weight: 300;
            font-size: 1em;

            background-color: $color-hot-pink;
            
            border: none;
            border-radius: 10px;
            @include box-shadow(0px 10px 15px 0px rgba(0, 0, 0, .25));
        }
    }

    @media (min-width: 1000px) {
        height: 100%;
        width: 65vw;
        
        margin-top: 5vh;
    
        @include flexbox;
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
    
        div.ip-selection-plus-copied {
            @include flexbox;
            @include flex-direction(row);
            @include align-items(start);
            
            p.copied {
                display: none;
                font-size: .9em !important;
                
                &.is-visible {
                    margin-left: 1vw;
                    display: inline;
                    
                    color: $color-dark-purple;
                    font-weight: 500 !important;
                    
                    animation: fadeOut 1.5s;
                }
            }
        }
        
        div.ip-section {
            height: auto;
            width: 100%;
            padding: 0px 20px 0 20px;

            h1 {
                line-height: .55em;
                font-size: 2em;
                font-weight: 300;
            }
    
            p {
                font-size: 1em;
                line-height: 1;
                margin-right: 10px;
                font-weight: 300;
            }
        }
    
        button.modal-button {
            height: 3em;
            width: auto;

            margin-top: 0;
            
            font-family: $font-stack;
            font-weight: 300;
            font-size: 1.25em;
            &:hover {
                cursor: grab;
                animation: hoverElement .25s normal forwards;
            }
        }
    }
}
