@import '../../Helper.scss';

div.user-section {
    height: 100%;

    @media (min-width: 300px) {
        div.userlist {
            width: 90vw;

            overflow-y: scroll !important;

            margin: 3.75vh 0 5vh 0;

            border-radius: 15px;
            background-color: $color-white;
            @include box-shadow(0px 10px 15px 0px rgba(0, 0, 0, .25));

            div.th {
                margin-top: 2vh;
                margin-bottom: 2vh;

                @include flexbox();
                @include flex-direction(column);
                @include align-items(center);
                
                line-height: .5em;
                
                h2 {
                    font-size: 1em;
                    font-weight: 400;
                }
            }
        }
    }

    @media (min-width: 1000px) {
        div.userlist {
            width: 65vw;
            
            div.th {
                width: 65vw;
                margin: 0 0 0 2.5vw;

                @include flex-direction(row);

                div.th-cell {
                    text-align: center;
                    width: 20vw;
                }
                
                h2 {
                    margin-top: 5vh;
                    font-size: 1.5em;
                    font-weight: 300;
                }
            }
        
            div.crafters {
                padding-bottom: 15px;
            }
        }
    }
}