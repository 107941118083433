@import '../../Helper.scss';

.Minecraft {
    @media (min-width: 300px) {
        div:has(> p.whitelist-blurb) {
            width: calc(90vw - 40px);
            margin-top: 2.7vh;
            padding: 20px;

            background-color: $color-white;

            border-radius: 15px;
            @include box-shadow(0px 10px 15px 0px rgba(0, 0, 0, .25));

            p {
                font-size: .8em;
                line-height: 1.5em;
                text-align: justify;
            }

            h3 {
                font-weight: 300;
                font-size: 1em;
                text-align: center;
            }
        }

        
    }

    @media (min-width: 1000px) {
        div:has(> p.whitelist-blurb) {
            width: calc(65vw - 40px);
            margin-top: 5vh;
            
            h3.whitelist-blurb {
                margin-left: 2vw;
                
                font-size: 1.17em;
            }
            
            p.whitelist-blurb {
                margin-left: 2vw;

                font-size: 1em;
                line-height: 1.75em;
            }
        }
        
    }
}
