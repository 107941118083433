@import '../../Helper.scss';

html:has(div.Minecraft) {
    height: 100% !important;
    overflow: scroll !important;

    background-color: #D0ACF7 !important;
}

.Minecraft {
    width: 100vw;

    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);

    font-weight: 300;
}